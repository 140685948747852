import {
  fromJS,
} from 'immutable';

import {
  loadState,
  setUser,
  setToken,
} from '../../helpers/storage';

import {
  SET_USER,
} from './constants';

const loadedState = loadState();
const defaultState = fromJS((loadedState && loadedState.auth) || {});

export default function authReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_USER:
      setUser(action.user.user);
      setToken(action.user.token);
      return state.set('user', fromJS(action.user.user)).set('token', action.user.token);
    default:
      return state;
  }
}
