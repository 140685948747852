export default (client, defaultParams) => ({
  getByTypeQR: (qr, type) => (
    client(`/device/qr/${type}/${qr}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  getByTypeRef: (ref, type) => (
    client(`/device/ref/${type}/${ref}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    })
  ),
  sendCommand: (ref, type, command) => (
    client(`/device/${type}/${ref}/command`, {
      method: 'POST',
      data: command,
      params: {
        ...defaultParams,
      },
    })
  ),
  lock: (ref, type) => (
    client(`/device/${type}/${ref}/lock`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),
  unlock: (ref, type) => (
    client(`/device/${type}/${ref}/unlock`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    })
  ),

  getMessage: (k) => {
    const messages = {
      '': '',
    };
    return (messages[k] || k);
  },
});
