/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useHistory, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo-paperboy.svg';

import pjson from '../../../package.json';
import styles from './index.module.scss';

import {
  getTenantOfUser,
} from '../../services/tenants';
import Loading from '../../components/Loading';

import stylesLayout from '../../common/layout.module.scss';

const AdminLayout = ({
  title,
  back,
  user,
  redirectTo,
  requiredPermissions,
  component: Component,
  ...props
}) => {
  const history = useHistory();
  const [tenant, setTenant] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(async () => {
    setLoading(true);
    const rsp = await getTenantOfUser();
    if (rsp.status) {
      setTenant(rsp.tenant);
    }
    setLoading(false);
  }, []);
  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  useEffect(() => {
    if (!user || !user.id) {
      history.push('/login');
    }
    return null;
  }, [user]);

  if (loading || !user || !tenant) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...props}
      render={(matchProps) => (
        <div className={styles.root}>
          <div className={styles.container}>
            <div className={styles.sidebar}>
              <div className={styles.inner}>
                <div className={styles.logo}>
                  <Link to="/dashboard">
                    <img src={logo} alt="Tommy" />
                  </Link>
                </div>
                <div className={styles.user}>
                  <div className={styles.name}>
                    {user.email}
                  </div>
                  <div className={styles.logout}>
                    <Link to="/logout">
                      <FontAwesomeIcon icon={faSignOutAlt} />
                      {' '}
                      Salir
                    </Link>

                  </div>
                </div>
                <div className={styles.menu}>
                  <div className={styles.options}>
                    <div className={`${styles.option} ${props.path === '/dashboard' ? styles.selected : null}`}>
                      <Link to="/dashboard">
                        <FontAwesomeIcon icon={faAngleRight} />
                        Dashboard
                      </Link>
                    </div>
                    <div className={`${styles.option} ${props.path === '/device' ? styles.selected : null}`}>
                      <Link to="/device">
                        <FontAwesomeIcon icon={faAngleRight} />
                        Devices
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className={styles.main}>
              <div className={styles.inner}>
                <Component {...matchProps} user={user} tenant={tenant} />
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.inner}>
                <div className={styles.version}>
                  {pjson.version}
                </div>
              </div>
            </div>

          </div>
        </div>
      )}
    />
  );
};

AdminLayout.defaultProps = {
  title: '',
  back: false,
  user: {},
  redirectTo: null,
  requiredPermissions: null,
};

AdminLayout.propTypes = {
  title: PropTypes.string,
  back: PropTypes.bool,
  user: PropTypes.object,
  redirectTo: PropTypes.string,
  path: PropTypes.any.isRequired,
  requiredPermissions: PropTypes.object,
  component: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  title: state.layout && state.layout.get('title'),
  back: state.layout && state.layout.get('back'),
  user: state && state.auth && state.auth.get('user') && state.auth.get('user').toJS(),
});

/*
const mapDispatchToProps = (dispatch) => ({

});
*/

export default connect(
  mapStateToProps,
  null, // mapDispatchToProps
)(AdminLayout);
