/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faLock,
  faUnlock,
  faPlug,
  faSync,
  faLocationArrow,
} from '@fortawesome/free-solid-svg-icons';

import { Button } from 'antd';
import styles from './index.module.scss';

import scooterLockedImg from './scooterLocked.svg';
import scooterUnlockedImg from './scooterUnlocked.svg';
import ReactSwipeButton from '../../SwipeButton';

const Scooter = ({
  device,
  onLock,
  onUnlock,
  onPowerOff,
  onRequestPosition,
  updating,
}) => {
  const [showMap, setShowMap] = useState(false);
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCIi-49zQc_qm-8TCRX26suYgt0oL1YETw&scale=2&center=${device.lat},${device.lng}&zoom=16&size=400x400&maptype=roadmap&markers=color:green%7Clabel:G%7C${device.lat},${device.lng}`;
  const getBatteryIcon = (n) => {
    if (n > 100 * (7 / 8)) {
      return faBatteryFull;
    }
    if (n > 100 * (5 / 8)) {
      return faBatteryThreeQuarters;
    }
    if (n > 100 * (3 / 8)) {
      return faBatteryHalf;
    }
    if (n > 100 * (1 / 8)) {
      return faBatteryQuarter;
    }
    return faBatteryEmpty;
  };
  return (
    <div className={styles.cnt}>
      <div className={styles.lockStatus}>
        <FontAwesomeIcon
          icon={device.data.locked ? faLock : faUnlock}
          className={device.data.locked ? styles.locked : styles.unlocked}
        />
      </div>

      <div className={styles.img}>
        <img src={device.data.locked ? scooterLockedImg : scooterUnlockedImg} alt="" />
      </div>
      <div className={styles.title}>
        {device.qr}
        {' '}
        -
        {' '}
        {device.ref}
      </div>
      <div className={styles.actions}>
        {device.data.locked
          ? (
            <ReactSwipeButton
              size="large"
              onSuccess={() => onUnlock('scooter', device.ref)}
              className={`${styles.unlock} ${updating ? styles.updating : ''}`}
              disabled={updating}
              updating={updating}
            >
              {updating && <FontAwesomeIcon icon={faSync} spin />}
              <FontAwesomeIcon icon={faUnlock} />
              {updating ? 'Unlocking' : 'Unlock'}
            </ReactSwipeButton>
          )
          : (
            <ReactSwipeButton
              size="large"
              onSuccess={() => onLock('scooter', device.ref)}
              className={`${styles.lock} ${updating ? styles.updating : ''}`}
              updating={updating}
              disabled={updating}
            >
              {updating && <FontAwesomeIcon icon={faSync} spin />}
              <FontAwesomeIcon icon={faLock} />
              {updating ? 'Locking' : 'Lock'}
            </ReactSwipeButton>
          )}
        {false && device.data.locked && <Button onClick={() => onPowerOff('scooter', device.ref)}>Power Off</Button>}
      </div>

      <div className={`${styles.battery} ${device.data.batsco < 20 ? styles.low : null}`}>
        <FontAwesomeIcon icon={getBatteryIcon(device.data.batsco)} style={{ fontSize: 18 }} />
        {device.data.charging === '1' ? <FontAwesomeIcon icon={faPlug} style={{ fontSize: 12 }} /> : null}
        {device.data.batsco}

      </div>
      <div className={styles.info}>
        Position:
        {' '}
        {device.lat}
        ,
        {' '}
        {device.lng}
        {device.geo_updated_at && (
          <>
            {' - '}
            {moment.utc(device.geo_updated_at).fromNow()}
          </>
        )}
        <br />
        RSSI:
        {' '}
        {device.data?.rssi}
        <br />
        {!showMap

          && (
            <a onClick={() => setShowMap(true)}>View map</a>

          )}
        {showMap
          && (
            <div style={{ width: '100%', marginTop: 20 }}>
              <img
                style={{ width: '100%', maxWidth: 400 }}
                src={mapUrl}
                alt="Map"
              />
            </div>
          )}
        <br />
        <br />
        Last Message from device:
        {' '}
        {JSON.stringify(device.data?.last_message_from_device)}
        {' - '}
        {device.data?.last_message_from_device_at ? `${moment.utc(device.data?.last_message_from_device_at).fromNow()} - ${device.data?.last_message_from_device_at}` : 'Unknown'}
        <br />
        Last Heartbeat from device:
        {' '}
        {device.data?.last_heartbeat_from_device_at ? moment.utc(device.data?.last_heartbeat_from_device_at).fromNow() : 'Unknown'}
        <br />
        Updated Device:
        {' '}
        {moment.utc(device.updated_at).fromNow()}
        <br />
        <br />
        <br />
        Lattis ID:
        {' '}
        {device.lattis_data?.bike_id
          ? (
            <a href={`https://bikeshare.lattis.io/#/bike-details/${device.lattis_data?.bike_id}`} target="_lattis">
              {device.lattis_data?.bike_id}
            </a>
          )
          : ' Unknownn'}
        <br />
        Lattis Status:
        {' '}
        {device.lattis_data?.status}
        {' '}
        -
        {' '}
        {device.lattis_data?.current_status}
        <br />
        Lattis Synced At:
        {' '}
        {device.lattis_data?.synced_at ? moment.utc(device.lattis_data?.synced_at).fromNow() : 'Unknown'}

      </div>
      <div className={styles.actionsAdmin}>
        <Button
          onClick={() => onUnlock('scooter', device.ref)}
          className={styles.unlock}
        >
          {updating && <FontAwesomeIcon icon={faSync} spin />}
          <FontAwesomeIcon icon={faUnlock} />
          Unlock
        </Button>
        <Button
          onClick={() => onLock('scooter', device.ref)}
          className={styles.lock}
        >
          {updating && <FontAwesomeIcon icon={faSync} spin />}
          <FontAwesomeIcon icon={faLock} />
          Lock
        </Button>
        <Button
          onClick={() => onRequestPosition('scooter', device.ref)}
          className={styles.unlock}
        >
          <FontAwesomeIcon icon={faLocationArrow} />
          Request geolocation
        </Button>
      </div>

    </div>
  );
};
Scooter.defaultProps = {
  onLock: () => { },
  onUnlock: () => { },
  onPowerOff: () => { },
  onRequestPosition: () => { },
  updating: false,
};
Scooter.propTypes = {
  device: PropTypes.object.isRequired,
  onLock: PropTypes.func,
  onUnlock: PropTypes.func,
  onPowerOff: PropTypes.func,
  onRequestPosition: PropTypes.func,
  updating: PropTypes.bool,
};

export default Scooter;
