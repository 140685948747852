/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';
import isMobile from 'is-mobile';
import { QrReader } from '@blackbox-vision/react-qr-reader';
import { useTorchLight } from '@blackbox-vision/use-torch-light';

import {
  Button,
  Input,
} from 'antd';

import ReactJson from 'react-json-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faQrcode } from '@fortawesome/free-solid-svg-icons';
import {
  getDeviceByTypeQROrRef,
  sendDeviceCommand,
  lockDevice,
  requestPosition,
  unlockDevice,
} from '../../services/devices';

import styles from './index.module.scss';
import Loading from '../../components/Loading';
import Scooter from '../../components/Devices/Scooter';
import stylesLayout from '../../common/layout.module.scss';

const LONG_POLL_PERIOD = 5000;
const MEDIUM_POLL_PERIOD = 2000;
const SHORT_POLL_PERIOD = 200;

const DeviceModule = () => {
  let deviceLastQRS = [];
  try {
    deviceLastQRS = localStorage.getItem('__device__last_qrs') ? JSON.parse(localStorage.getItem('__device__last_qrs')) : [];
  } catch (e) {
    deviceLastQRS = [];
  }
  const [scanning, setScanning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updatingDevice, setUpdatingDevice] = useState(false);
  const updatingDeviceRef = useRef(updatingDevice);
  updatingDeviceRef.current = updatingDevice;
  const [qr, setQr] = useState(localStorage.getItem('__device__qr') || '');
  const [lastQRs, setLastQRs] = useState(deviceLastQRS);
  const [device, setDevice] = useState(null);
  const [deviceIsLocked, setDeviceIsLocked] = useState(null);
  const [pidSync, setPidSync] = useState(null);
  const pidSyncRef = useRef(pidSync);
  pidSyncRef.current = pidSync;

  const [pidScan, setPidScan] = useState(null);
  const streamRef = useRef(null);
  const [torchOn, torchToggle] = useTorchLight(streamRef.current);

  useEffect(() => {
    if (deviceIsLocked === null) { return; }
    if (pidSyncRef.current) {
      clearTimeout(pidSyncRef.current);
    }
    setUpdatingDevice(false);
  }, [deviceIsLocked]);

  useEffect(async () => {
    if (pidSyncRef.current) {
      clearTimeout(pidSyncRef.current);
    }
    localStorage.setItem('__device__qr', qr);
  }, [qr]);

  const endScanning = () => {
    window.clearTimeout(pidScan);
    setTimeout(() => setScanning(false), 100);
  };
  const startScanning = () => {
    setTimeout(() => setScanning(true), 100);
    window.clearTimeout(pidScan);
    const pid = window.setTimeout(
      () => endScanning(),
      30000,
    );
    setPidScan(pid);
  };

  const loadDevice = async (currentDevice, deviceQr, deviceType, forceSet, refreshPeriod) => {
    const rsp = await getDeviceByTypeQROrRef({
      type: deviceType,
      code: deviceQr,
    });
    let newCurrentDevice = currentDevice;
    let newRefreshPeriod = updatingDeviceRef.current ? refreshPeriod : LONG_POLL_PERIOD;
    if (rsp && !rsp.status) {
      // setDevice(null);
      setError(rsp);
      console.log(error);
      newCurrentDevice = false;
      newRefreshPeriod = LONG_POLL_PERIOD;
      setLoading(false);
      setUpdatingDevice(false);
    }
    if (rsp && rsp.status) {
      if (forceSet || !currentDevice || (rsp.data.updated_at !== currentDevice.updated_at)) {
        setDevice(rsp.data);
        if (rsp.data && rsp.data.data) {
          setDeviceIsLocked(rsp.data.data.locked);
        }
        newCurrentDevice = rsp.data;
        setLoading(false);
      }
    }
    if (newCurrentDevice) {
      if (pidSyncRef.current) {
        clearTimeout(pidSyncRef.current);
      }
      const pid = window.setTimeout(() => {
        loadDevice(newCurrentDevice, deviceQr, deviceType, false, newRefreshPeriod);
      }, newRefreshPeriod);
      setPidSync(pid);
    }
  };

  const onLock = async (deviceType, deviceRef) => {
    setUpdatingDevice(true);
    await lockDevice({
      type: deviceType,
      ref: deviceRef,
    });
    window.setTimeout(() => {
      loadDevice(device, qr, 'scooter', false, SHORT_POLL_PERIOD);
    }, MEDIUM_POLL_PERIOD);
  };
  const onRequestPosition = async (deviceType, deviceRef) => {
    await requestPosition({
      type: deviceType,
      ref: deviceRef,
    });
    window.setTimeout(() => {
      loadDevice(device, qr, 'scooter', false, SHORT_POLL_PERIOD);
    }, MEDIUM_POLL_PERIOD);
  };
  const onUnlock = async (deviceType, deviceRef) => {
    setUpdatingDevice(true);
    await unlockDevice({
      type: deviceType,
      ref: deviceRef,
    });
    window.setTimeout(() => {
      loadDevice(device, qr, 'scooter', false, SHORT_POLL_PERIOD);
    }, MEDIUM_POLL_PERIOD);
  };
  const onPowerOff = async (deviceType, deviceRef) => {
    setUpdatingDevice(true);
    await sendDeviceCommand({
      type: deviceType,
      ref: deviceRef,
      command: '17,1',
    });
  };

  const onStartSearch = async (forceQR) => {
    const searchQR = forceQR || qr;
    if (!searchQR) {
      return;
    }
    let newLastQRs = lastQRs;
    if (!Array.isArray(newLastQRs) || !newLastQRs.length > 0) {
      newLastQRs = [];
    }
    newLastQRs.unshift(searchQR.toUpperCase());
    newLastQRs = [...new Set(newLastQRs)].slice(0, 5);
    setLastQRs(newLastQRs);
    localStorage.setItem('__device__last_qrs', JSON.stringify(newLastQRs));
    setUpdatingDevice(false);
    setDevice(null);
    setLoading(true);
    loadDevice(device, searchQR, 'scooter', true, LONG_POLL_PERIOD);
  };

  useEffect(() => {
    if (qr) {
      onStartSearch(qr);
    }
  }, []);

  const getDeviceComponent = (d) => {
    switch (d.type) {
      case 'scooter':
      default:
        return (
          <Scooter
            device={d}
            onLock={onLock}
            onUnlock={onUnlock}
            onRequestPosition={onRequestPosition}
            onPowerOff={onPowerOff}
            updating={updatingDevice}
          />
        );
    }
  };

  return (
    <div className={styles.cnt}>
      {scanning
        && (
          <div className={styles.modalScan}>
            <div>
              <div className={styles.closeBtn} onClick={endScanning}>
                &times;
              </div>
            </div>
            <div>

              <QrReader
                resolution={600}
                constraints={{
                  aspectRatio: 1,
                  facingMode: 'environment',
                }}
                onLoad={({ stream }) => { streamRef.current = stream; }}
                onResult={(result) => {
                  if (result) {
                    const newQr = result?.text.slice(result?.text.length - 4);
                    setQr(newQr);
                    onStartSearch(newQr);
                    endScanning();
                  }
                }}
              />
              {false
                && <FontAwesomeIcon onClick={torchToggle} icon={faLightbulb} color={torchOn ? '#999' : 'inherit'} style={{ fontSize: 30 }} />}
            </div>
          </div>
        )}
      <div className={stylesLayout.page}>
        {!isMobile
          && (
            <div className={stylesLayout.title}>
              Device
            </div>
          )}
        <div className={stylesLayout.content}>
          <div className={styles.input}>
            <div className={styles.inputInner}>
              <div>
                <Input value={qr} placeholder="Buscar por QR..." onChange={(e) => setQr(e.target.value.toUpperCase())} onKeyDown={(e) => (e.key === 'Enter' ? onStartSearch() : null)} />
                {lastQRs && lastQRs.length > 0
                  && (
                    <div className={styles.qrlinks}>
                      Recent:
                      {' '}
                      {lastQRs.map((lqr) => (
                        <a
                          key={lqr}
                          className={styles.qrlink}
                          onClick={() => { setQr(lqr); onStartSearch(lqr); }}
                        >
                          {lqr}
                        </a>

                      ))}
                    </div>
                  )}
              </div>
              <div className={styles.scan} onClick={startScanning}>
                <FontAwesomeIcon icon={faQrcode} />
              </div>
            </div>
            <Button type="primary" onClick={() => onStartSearch()}>Buscar</Button>
          </div>
          {device === null && loading && <div className={styles.loading}><Loading /></div>}
          {device === false && <div className={`${styles.message} ${styles.notFound}`}>Device not found</div>}
          {error && <div className={`${styles.message} ${styles.notFound}`}>{JSON.stringify(error)}</div>}
          {device && device.data && (
            <>
              {getDeviceComponent(device)}
              {false && <ReactJson src={device} />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

DeviceModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  tenant: PropTypes.object.isRequired,
};

export default DeviceModule;
