/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'antd';

import Loading from '../../components/Loading';

// import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';

const DashboardModule = ({
  tenant,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(false);
  }, []);

  if (loading || !tenant) {
    return <div className={stylesLayout.page}><Loading /></div>;
  }

  // const stepsStore = getStepsStore(store);
  // const pendingStepsStore = (stepsStore.filter((x) => !x.completed));
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        {tenant.name}
      </div>
      <div className={stylesLayout.content}>

        <Row gutter={24}>
          <Col sm={16} xs={24}>
            <br />

          </Col>
          <Col sm={8} xs={24} />
        </Row>
      </div>
    </div>
  );
};

DashboardModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
};

export default DashboardModule;
