import apiClient from '../api';

export const getDeviceByTypeQROrRef = async ({
  code, type = 'scooter',
}) => {
  try {
    let fn = null;
    if (code.length === 4) {
      fn = apiClient.device.getByTypeQR;
    } else {
      fn = apiClient.device.getByTypeRef;
    }
    const { data } = await fn(
      code,
      type,
    );
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.device.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};
export const sendDeviceCommand = async ({
  ref, command, type = 'scooter',
}) => {
  try {
    console.log('send command', command);
    const { data } = await apiClient.device.sendCommand(
      ref,
      type,
      command,
    );
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.device.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};

export const requestPosition = async ({
  ref, type = 'scooter',
}) => sendDeviceCommand({
  ref,
  command: '201',
  type,
});
export const lockDevice = async ({
  ref, type = 'scooter',
}) => {
  try {
    const { data } = await apiClient.device.lock(
      ref,
      type,
    );
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.device.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};
export const unlockDevice = async ({
  ref, type = 'scooter',
}) => {
  try {
    const { data } = await apiClient.device.unlock(
      ref,
      type,
    );
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.device.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};
export default {
  getDeviceByTypeQROrRef,
  lockDevice,
  requestPosition,
  unlockDevice,
};
