/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';

import BlankLayout from './layouts/BlankLayout';
import AdminLayout from './layouts/AdminLayout';

import DashboardModule from './modules/DashboardModule';
import LoginModule from './modules/LoginModule';
import LogoutModule from './modules/LogoutModule';
import DeviceModule from './modules/DeviceModule';

const Routes = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search.slice(1)); // id=123
    let currentParams = {};
    try {
      currentParams = JSON.parse(localStorage.getItem('__parameters'));
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
    const parameters = {
      ...currentParams,
    };
    // eslint-disable-next-line guard-for-in
    for (const [k, v] of params) {
      parameters[k] = v;
    }
    localStorage.setItem('__parameters', JSON.stringify(parameters));
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <BlankLayout exact path="/login" component={LoginModule} />
        <BlankLayout exact path="/logout" component={LogoutModule} />
        <AdminLayout exact path="/" redirectTo="/device" />
        <AdminLayout exact path="/dashboard" component={DashboardModule} />
        <AdminLayout exact path="/device" component={DeviceModule} />
      </Switch>
    </BrowserRouter>
  );
};

Routes.defaultProps = {
};

Routes.propTypes = {
};

export default Routes;
