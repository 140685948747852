/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
// import PropTypes from 'prop-types';
// import styles from './index.module.scss';

import { Spin } from 'antd';

import stylesLayout from '../../common/layout.module.scss';

const Loading = () => (
  <div className={stylesLayout.loading}><Spin /></div>
);

export default Loading;
