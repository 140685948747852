import apiClient from '../api';

export const createTenant = async (payload) => {
  try {
    try {
      // eslint-disable-next-line no-param-reassign
      payload.creation_parameters = JSON.parse(localStorage.getItem('__parameters'));
    } catch (e) {
      // eslint-disable-next-line no-param-reassign
      payload.creation_parameters = {};
    }

    const { data } = await apiClient.tenants.create(payload);
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.tenants.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
    msg: 'Error desconocido',
  };
};

export const getTenantOfUser = async () => {
  try {
    const { data } = await apiClient.users.getTenant();
    return {
      status: true,
      tenant: data,
    };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.tenants.getMessage(e.response.data.error),
        };
      }
    }
  }
  return {
    status: false,
  };
};

export const updateTenant = async (payload) => {
  try {
    const { data } = await apiClient.tenants.update(payload);
    return {
      status: true,
      data,
    };
  } catch (e) {
    if (e && e.response && e.response.status === 400) {
      const rsp = e.response.data;
      if (rsp.msg) {
        return {
          status: false,
          msg: apiClient.tenants.getMessage(e.response.data.error),
        };
      }
    }
    return {
      status: false,
      msg: 'Error desconocido',
    };
  }
};

export default {
  createTenant,
  getTenantOfUser,
  updateTenant,
};
